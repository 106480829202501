import { Button, ButtonGroup, Grid as MUIGrid, Stack } from '@mui/material';
import { checkTimeRange, getDatesBetween, getOccasionsFromActivities } from '../../helper/Occasions';
import { ColumnHeader } from './ColumnHeader';
import { Activity, Facility, Occasion } from '../../types';
import { Card } from './Card';
import { useDrawer } from '../../contexts/DrawerContext';
import { useFocusedActivity } from '../../contexts/FocusedActivityContext';
import { useFocusedOccasion } from '../../contexts/FocusedOccasionContext';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { baseUrl } from '../../constants/endpoints';
import { getAllFacilityIds } from '../../helper/Facilities';

type Props = {
	searchTerm: string;
	activities: Activity[] | undefined;
	relativeWeeks: number;
	loading: boolean;
};

export const Grid = ({ searchTerm, activities, relativeWeeks, loading }: Props) => {
	const { setOpen } = useDrawer();
	const { setFocusedOccasion } = useFocusedOccasion();
	const { setFocusedActivity } = useFocusedActivity();
	const handleOpen = async (occasion: Occasion, activity: Activity) => {
		setFocusedActivity(activity);
		setFocusedOccasion(occasion);
		setOpen(true);
	};
	const [showOnlyEmpty, setShowOnlyEmpty] = useState(false);
	const [showAfterWork, setShowAfterWork] = useState(false);
	const [privateLesson, setPrivateLesson] = useState(false);
	const [showMachine, setShowMachine] = useState(false);
	const [hiddenFacilities, setHiddenFacilities] = useState<number[]>([]);
	const { data: facilities } = useQuery<Facility[]>({
		queryKey: ['facilities'],
		queryFn: () => fetch(`${baseUrl}/facilities`).then((res) => res.json()),
	});
	return (
		<Stack spacing={2}>
			<Stack direction={'row'} spacing={2}>
				<ButtonGroup>
					<Button variant={showOnlyEmpty ? 'contained' : 'outlined'} onClick={() => setShowOnlyEmpty(!showOnlyEmpty)}>
						Only empty
					</Button>
					<Button
						variant={
							getAllFacilityIds(activities ?? []).every((facilityId) => hiddenFacilities.includes(facilityId)) ? 'contained' : 'outlined'
						}
						onClick={() =>
							getAllFacilityIds(activities ?? []).every((facilityId) => hiddenFacilities.includes(facilityId))
								? setHiddenFacilities([])
								: setHiddenFacilities(getAllFacilityIds(activities ?? []))
						}
					>
						{`${getAllFacilityIds(activities ?? []).every((facilityId) => hiddenFacilities.includes(facilityId)) ? 'show' : 'hide'} all`}
					</Button>
				</ButtonGroup>
				<ButtonGroup>
					<Button variant={showAfterWork ? 'contained' : 'outlined'} onClick={() => setShowAfterWork(!showAfterWork)}>
						After work
					</Button>
				</ButtonGroup>
				<ButtonGroup>
					<Button variant={privateLesson ? 'contained' : 'outlined'} onClick={() => setPrivateLesson(!privateLesson)}>
						Private lesson
					</Button>
				</ButtonGroup>
				<ButtonGroup>
					<Button variant={showMachine ? 'contained' : 'outlined'} onClick={() => setShowMachine(!showMachine)}>
						Show machine
					</Button>
				</ButtonGroup>
			</Stack>
			<ButtonGroup>
				{facilities
					?.filter((facility) => getAllFacilityIds(activities ?? []).includes(facility.id))
					.map((facility) => (
						<Button
							size="small"
							color="secondary"
							key={facility.id}
							variant={
								getAllFacilityIds(activities ?? [])
									.filter((facilityId) => !hiddenFacilities.includes(facilityId))
									.includes(facility.id)
									? 'contained'
									: 'outlined'
							}
							onClick={() =>
								hiddenFacilities.includes(facility.id)
									? setHiddenFacilities([...hiddenFacilities].filter((facilityId) => facilityId !== facility.id))
									: setHiddenFacilities([...hiddenFacilities].concat(facility.id))
							}
						>
							{facility.name}
						</Button>
					))}
			</ButtonGroup>
			<MUIGrid container>
				{getDatesBetween(0 + relativeWeeks * 7, 6 + relativeWeeks * 7).map((date) => (
					<MUIGrid container direction="column" item={true} xs={1} columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 7 }} key={date.getTime()}>
						<ColumnHeader dateToView={date} loading={loading} />
						{getOccasionsFromActivities(activities, date)
							/* HIDE ALL */
							.filter((occasion) => {
								const relatedActivity = activities?.find((activity) => activity.id === occasion.activityId);
								return !hiddenFacilities.includes(relatedActivity?.facilityId ?? 0);
							})
							/* AFTER WORK */
							.filter((occassion) => {
								if (showAfterWork) {
									return checkTimeRange(occassion);
								}
								return true;
							})
							/* PRIVATE LESSON */
							.filter((occassion) => {
								if (!privateLesson) {
									return true;
								}
								const relatedActivity = activities?.find((activity) => activity.id === occassion.activityId);
								return relatedActivity?.name?.toLocaleLowerCase().includes('privatlektion');
							})
							/*SHOW MACHINE */
							.filter((occassion) => {
								if (showMachine) {
									return true;
								}
								const relatedActivity = activities?.find((activity) => activity.id === occassion.activityId);
								return !relatedActivity?.name?.toLocaleLowerCase().includes('bollmaskin');
							})
							/* ONLY EMPTY */
							.filter((occasion) =>
								showOnlyEmpty
									? occasion.occasionParticipants.length === 0
									: occasion.occasionParticipants.some((participant) => {
											if (searchTerm.length === 0) {
												return true;
											} else if (searchTerm.split(' ').length === 1) {
												return (
													participant.firstName?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
													participant.lastName?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
												);
											} else if (searchTerm.split(' ').length === 2) {
												return `${participant.firstName?.toLocaleLowerCase()} ${participant.lastName?.toLocaleLowerCase()}`.includes(
													searchTerm.toLocaleLowerCase()
												);
											} else {
												return false;
											}
									  })
							)
							.map((occasion) => {
								const relatedActivity = activities?.find((activity) => activity.id === occasion.activityId);
								return (
									relatedActivity && (
										<Card
											loading={loading}
											activity={relatedActivity}
											occasion={occasion}
											key={occasion.id}
											handleClick={() => handleOpen(occasion, relatedActivity)}
										/>
									)
								);
							})}
					</MUIGrid>
				))}
			</MUIGrid>
		</Stack>
	);
};
