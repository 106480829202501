import { TextField } from '@mui/material';

type Props = {
	setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
};

export const SearchBar = ({ setSearchTerm }: Props) => {
	const handleSearch = (event: any) => {
		setSearchTerm(event.target.value);
	};

	return (
		<TextField
			id="standard-search"
			label="Find player"
			type="search"
			variant="standard"
			onChange={handleSearch}
			style={{ flex: 1, margin: 12 }}
		/>
	);
};
