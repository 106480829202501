import './App.css';
import { WeekGrid } from './components/WeekGrid';

function App() {
	return (
		<div className="App">
			<WeekGrid />
		</div>
	);
}

export default App;
