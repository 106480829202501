import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Occasion } from '../types';

// Define a type for the context value
interface FocusedOccasionContextType {
	focusedOccasion: Occasion | undefined;
	setFocusedOccasion: React.Dispatch<React.SetStateAction<Occasion | undefined>>;
}

// Create the context
const FocusedOccasionContext = createContext<FocusedOccasionContextType | undefined>(undefined);

// Create a custom hook to use the context
export const useFocusedOccasion = () => {
	const context = useContext(FocusedOccasionContext);
	if (!context) {
		throw new Error('useFocusedOccasion must be used within an FocusedOccasionProvider');
	}
	return context;
};

// Define type for children prop
interface FocusedOccasionProviderProps {
	children: ReactNode;
}

// Create a provider component
export const FocusedOccasionProvider: React.FC<FocusedOccasionProviderProps> = ({ children }) => {
	const [focusedOccasion, setFocusedOccasion] = useState<Occasion | undefined>();

	return <FocusedOccasionContext.Provider value={{ focusedOccasion, setFocusedOccasion }}>{children}</FocusedOccasionContext.Provider>;
};
