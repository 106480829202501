import { Box, Skeleton, Typography } from '@mui/material';

type Props = {
	dateToView: Date;
	loading: boolean;
};

const weekDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const ColumnHeader = ({ dateToView, loading }: Props) => (
	<Box sx={{ m: 1 }}>
		<Typography variant="h6">{loading ? <Skeleton /> : dateToView.toLocaleDateString()}</Typography>
		<Typography variant="subtitle1">{loading ? <Skeleton /> : weekDayNames[dateToView.getDay()]}</Typography>
	</Box>
);
