import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DrawerProvider } from './contexts/DrawerContext';
import { FocusedOccasionProvider } from './contexts/FocusedOccasionContext';
import { FocusedActivityProvider } from './contexts/FocusedActivityContext';

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<DrawerProvider>
				<FocusedOccasionProvider>
					<FocusedActivityProvider>
						<App />
					</FocusedActivityProvider>
				</FocusedOccasionProvider>
			</DrawerProvider>
		</QueryClientProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
