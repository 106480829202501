import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define a type for the context value
interface DrawerContextType {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context
const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

// Create a custom hook to use the context
export const useDrawer = () => {
	const context = useContext(DrawerContext);
	if (!context) {
		throw new Error('useDrawer must be used within an DrawerProvider');
	}
	return context;
};

// Define type for children prop
interface DrawerProviderProps {
	children: ReactNode;
}

// Create a provider component
export const DrawerProvider: React.FC<DrawerProviderProps> = ({ children }) => {
	const [open, setOpen] = useState(false);

	return <DrawerContext.Provider value={{ open, setOpen }}>{children}</DrawerContext.Provider>;
};
