import { Box, Drawer as MUIDrawer, useMediaQuery } from '@mui/material';
import { useDrawer } from '../../contexts/DrawerContext';
import { Header } from './Header';
import { List } from './List';

export const Drawer = () => {
	const { open, setOpen } = useDrawer();
	const isDesktop = useMediaQuery('(min-width:600px)'); // Adjust the breakpoint as per your design requirements
	return (
		<MUIDrawer open={open} onClose={() => setOpen(false)}>
			<Box
				sx={{
					maxWidth: isDesktop ? '20vw' : '70vw',
					p: 4,
				}}
			>
				<Header />
				<List />
			</Box>
		</MUIDrawer>
	);
};
