import { Activity } from '../types';

export const getAllFacilityIds = (activities: Activity[]): number[] => {
	const facilityIds: number[] = [];
	activities.forEach((activity) => {
		if (!facilityIds.includes(activity.facilityId)) {
			facilityIds.push(activity.facilityId);
		}
	});
	return facilityIds;
};
