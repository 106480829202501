import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Activity } from '../types';

// Define a type for the context value
interface FocusedActivityContextType {
	focusedActivity: Activity | undefined;
	setFocusedActivity: React.Dispatch<React.SetStateAction<Activity | undefined>>;
}

// Create the context
const FocusedActivityContext = createContext<FocusedActivityContextType | undefined>(undefined);

// Create a custom hook to use the context
export const useFocusedActivity = () => {
	const context = useContext(FocusedActivityContext);
	if (!context) {
		throw new Error('useFocusedActivity must be used within an FocusedActivityProvider');
	}
	return context;
};

// Define type for children prop
interface FocusedActivityProviderProps {
	children: ReactNode;
}

// Create a provider component
export const FocusedActivityProvider: React.FC<FocusedActivityProviderProps> = ({ children }) => {
	const [focusedActivity, setFocusedActivity] = useState<Activity | undefined>();

	return <FocusedActivityContext.Provider value={{ focusedActivity, setFocusedActivity }}>{children}</FocusedActivityContext.Provider>;
};
