import { Button, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { baseUrl } from '../constants/endpoints';
import { useState } from 'react';
import { SearchBar } from './Grid/SearchBar';
import { Drawer } from './Drawer/Drawer';
import { Activity } from '../types';
import { Grid } from './Grid/Grid';
import { calculateWeeksToGivenDate } from '../constants/numbers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const WeekGrid = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [relativeWeeks, setRelativeWeeks] = useState<number>(0);
	const {
		data: activities,
		isLoading: isLoadingActivities,
		error: errorActivities,
	} = useQuery<Activity[]>({
		queryKey: ['activities'],
		queryFn: () => fetch(`${baseUrl}/upcoming-week`).then((res) => res.json()),
	});
	const {
		data: archivedActivities,
		isLoading: isLoadingArchivedActivities,
		error: errorArchivedActivities,
	} = useQuery<Activity[]>({
		queryKey: ['archived-activities', relativeWeeks],
		queryFn: () => fetch(`${baseUrl}/past-week/${relativeWeeks * -1}`).then((res) => res.json()),
		enabled: relativeWeeks < 0,
	});

	const data = relativeWeeks < 0 ? archivedActivities : activities;
	const error = errorActivities || errorArchivedActivities;
	const loading = isLoadingActivities || (relativeWeeks < 0 && isLoadingArchivedActivities);

	if (error) return <div>Error fetching data: {error.message}</div>;
	return (
		<>
			<Stack direction="row" spacing={1}>
				<Button
					disabled={relativeWeeks < calculateWeeksToGivenDate()}
					onClick={() => setRelativeWeeks(relativeWeeks - 1)}
					startIcon={<ArrowBackIcon />}
				/>
				<SearchBar setSearchTerm={setSearchTerm} />
				<Button disabled={relativeWeeks === 0} onClick={() => setRelativeWeeks(relativeWeeks + 1)} endIcon={<ArrowForwardIcon />} />
			</Stack>
			<Grid searchTerm={searchTerm} activities={data} relativeWeeks={relativeWeeks} loading={loading} />
			<Drawer />
		</>
	);
};
