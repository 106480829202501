import { Card as MUICard, CardActionArea, CardContent, LinearProgress, ListItemText } from '@mui/material';
import { Activity, Occasion } from '../../types';

type Props = {
	activity: Activity;
	occasion: Occasion;
	loading: boolean;
	handleClick: Function;
};

const timeForDateString = (dateString: string) => {
	const date = new Date(dateString);
	const hour = date.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if needed
	const minute = date.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if needed

	return `${hour}:${minute}`;
};

export const Card = ({ activity, occasion, handleClick }: Props) => (
	<MUICard
		sx={{
			backgroundColor: occasion.isBookable ? '#d3d3d3' : '#a0a0a0',
			margin: '8px',
		}}
	>
		<CardActionArea
			onClick={() => {
				handleClick();
			}}
		>
			<CardContent>
				<ListItemText
					primary={activity.name}
					secondary={`${timeForDateString(occasion.startTime)} - ${timeForDateString(occasion.endTime)}`}
				/>
				<LinearProgress value={(occasion.participants / occasion.maxNumParticipants) * 100} variant="determinate"></LinearProgress>
			</CardContent>
		</CardActionArea>
	</MUICard>
);
